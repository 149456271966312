.server-config-main-container {
    border-radius: 20px;
    background-color: #fff;
    width: 60%;
    padding: 29px 50px 30px 50px;

    .config-container {
        display: flex;
        gap: 60px;

        .progress-sidebar {
            padding: 30px 0 30px 0px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-radius: 15px;
            background-color: #F9FAFB;
            width: 40%;

            .progress-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                .progress-number {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #9EA3A9;
                    color: #fff;
                    border-radius: 50%;
                }

                .progress-content {
                    font-size: 16px;
                    color: #9EA3A9;
                }

                .progress-line {
                    height: 80px;
                    border-left: 0.75px solid #CED0D3;
                }

                &.completed {
                    .progress-number {
                        background-color: #28a745;
                    }

                    .progress-content {
                        color: #28a745;
                    }
                }
            }
        }

        .main-form {
            padding-top: 45px;
            gap: 25px;
            display: flex;
            width: 50%;
            flex-direction: column;

            .form1 {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .form-header {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    h3 {
                        margin: 0;
                        font-size: 20px;
                    }

                    span {
                        color: #9EA3A9;
                        font-size: 14px;
                    }
                }

                .form-input-container {
                    display: flex;
                    flex-direction: column;
                    gap: 20px
                }

                .example-db-url {
                    font-size: 14px;
                    color: #9EA3A9;
                }

                .db-form-btn {
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .form2 {
                display: flex;
                flex-direction: column;
                gap: 60px;

                .form-header {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    h3 {
                        margin: 0;
                        font-size: 20px;
                    }

                    span {
                        color: #9EA3A9;
                        font-size: 14px;
                    }
                }


                .storage-type {
                    display: flex;
                    gap: 40px;

                    .cloud-storage {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        border: 0.75px solid #CED0D3;
                        border-radius: 6px;
                        height: 119px;
                        padding: 13px;
                        gap: 30px;

                        .cloud-checkbox {
                            justify-content: space-between;
                            display: flex;

                            p {
                                border-radius: 3px;
                                padding: 3px;
                                position: absolute;
                                top: 2px;
                                right: 2px;
                                background-color: #ECFDF3;
                                color: #118D57;
                                margin: 0;
                            }

                            .custom-checkbox {
                                accent-color: green;
                                cursor: pointer;
                                width: 25px;
                                height: 23px;
                            }

                        }

                        h4 {
                            margin: 0;

                            .sub-content {
                                font-size: 14px;
                            }
                        }
                    }

                    .local-storage {
                        padding: 13px;
                        display: flex;
                        flex-direction: column;
                        border: 0.75px solid #CED0D3;
                        border-radius: 6px;
                        width: 205px;
                        height: 117px;
                        gap: 30px;

                        .custom-checkbox {
                            accent-color: green;
                            cursor: pointer;
                            width: 25px;
                            height: 23px;
                        }

                        h4 {
                            margin: 0;

                        }
                    }
                }

                .storage-btn {
                    width: 138px;
                }
            }

            .local-storage-form {
                display: flex;
                flex-direction: column;
                gap: 25px;

                .form-header {
                    display: flex;
                    gap: 17px;
                    flex-direction: column;

                    h3 {
                        font-size: 20px;
                        margin: 0;
                    }

                    span {
                        color: #9EA3A9;
                        font-size: 14px;
                    }
                }

                .local-storage-form-content {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    .local-from-button {
                        width: 138px;
                    }
                }
            }

            .cloud-storage-form {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .form-header {
                    display: flex;
                    gap: 17px;
                    flex-direction: column;

                    h3 {
                        font-size: 20px;
                        margin: 0;
                    }

                    span {
                        color: #9EA3A9;
                        font-size: 14px;
                    }
                }

                .cloud-storage-form-content {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .cloud-from-button {
                        width: 138px;
                    }

                    .smtp-from-button {
                        display: flex;
                        justify-content: space-between;
                        .skip{
                            color: #30A84B;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                }
            }

            .open-search-form {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .form-header {
                    display: flex;
                    gap: 17px;
                    flex-direction: column;

                    h3 {
                        font-size: 20px;
                        margin: 0;
                    }

                    span {
                        color: #9EA3A9;
                        font-size: 14px;
                    }
                }

                .open-search-form-content {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    .search-from-button {
                        // width: 138px;

                        .open-search-footer {
                            display: flex;
                            justify-content: space-between;

                            .skip {
                                color: #30A84B;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}