.configurations {
    padding: 20px;

    .new-storage-header-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .new-storage-header {
        padding: 10px;
        cursor: pointer;
        color: $success-text;
    }

    .form-fields {
        display: flex;
        flex-direction: column;
        gap: 0px;
        margin-bottom: 30px;
        border: 0.75px solid #CED0D3;
        border-radius: 6px;
        width: 100%;

        .form-button-group-config {
            display: flex;
            justify-content: flex-end;
            gap: 20px;
            padding: 15px;
        }

        .storage-tab {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
        }

        .line {
            border: none;
            border-top: 1px solid #CED0D3;
            /* Adjust color and width as needed */
            width: 100%;
            margin: 0;
            /* Remove default margin */
            padding: 0px;
        }

        .header {
            padding: 10px;
            cursor: pointer;
        }

        .storage-header-selected {
            padding: 10px;
            cursor: pointer;
            color: $success-text;
        }

        .storage-header {
            padding: 10px;
            cursor: pointer;
        }


        .form-fields-single-row {
            width: 80%;
            padding: 10px;

            .input-field {
                display: flex;
                gap: 40px;
                margin-bottom: 30px;
            }

            .input-field-single {
                width: 48%;
            }

            .open-search-field {
                margin-bottom: 30px;
            }
        }

    }

    .form-fields-row {
        display: grid;
        grid-template-columns: 47% 47%;
        justify-content: space-between;
    }


}