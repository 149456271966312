.content-layout {
  height: 100%;
  border-radius: 8px;
  padding: 12px 22px;

  display: grid;
  grid-template-rows: max-content auto;

  .content-layout-wrap {
    padding: 20px 15px;
    height: 100%;
    border-radius: 8px;
    background-color: $white-color;
    box-shadow: 0px 0px 9px #f0f0f0;

    display: grid;
    grid-template-rows: max-content 1fr ;

    .content-layout-tabs {
      display: flex;

      .each-tab {
        position: relative;
        padding: 8px 15px 10px;
        cursor: pointer;

        h1 {
          font-size: 14px;
          font-weight: 500;
          color: var(--active-tab-color);
          transition: 0.2s all ease-in-out;
        }

        .active-tab-border {
          width: var(--active-tab-width);
          height: 3px;
          background-color: $success-button-background-color;
          border-radius: 3px;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: 0.1s all ease-in-out;
        }
      }
    }

    .content-layout-container {
      height: 100%;
    }
  }
}