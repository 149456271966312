.license-activation-main-container {
    display: flex;
    width: 100%;
    justify-content: center;

    .license-activation-container {
        border-radius: 20px;
        background-color: #fff;
        width: 30%;
        padding: 29px 50px 30px 50px;

        .activation-form {
            display: flex;
            flex-direction: column;
            gap: 32px;

            .activation-header {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;

                h3 {
                    font-size: 20px;
                    margin: 0;
                }

                .activation-sub-header {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    span {
                        color: #5C646D;
                        font-size: 14px;
                        margin: 0;
                    }
                }
            }

            // .activation-input {
            //     display: flex;
            //     flex-direction: column;
            //     gap: 20px;

            //     .input {
            //         .input-box {
            //             padding: 12px 9px;
            //         }
            //     }

            //     .activation-tooltip {
            //         cursor: pointer;
            //         display: flex;
            //         justify-content: flex-end;
            //         text-decoration: underline;
            //     }
            // }
            .activation-input {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .input {
                    .input-box {
                        padding: 12px 9px;
                    }
                }

                .activation-tooltip {
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    text-decoration: underline;

                }

                .tooltip-list {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
            }


            .activation-btn {
                .btn .success-button {
                    padding: 13px 20px;
                }
            }
        }
    }

    .start-application-container {
        border-radius: 20px;
        background-color: #fff;
        width: 30%;
        padding: 29px 50px 30px 50px;

        .start-few-step-container {
            display: flex;
            flex-direction: column;
            gap: 32px;

            .container-header {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;

                h3 {
                    margin: 0;
                    font-size: 20px;
                }

                .sub-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 12px;
                    font-size: 14px;
                }
            }

            .main-content {
                display: flex;
                gap: 10px;

                .start-image {
                    width: 21px;
                }

                .few-step-content {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}