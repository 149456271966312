.content {
    .main-content {
        .content-area {
            .my-content {
                .my-content-area-form-1 {
                    .schedule-form {
                        display: flex;
                        flex-direction: column;
                        padding: 20px;
                        gap: 30px 60px;

                        .form-field-row,
                        .form-field-1 {
                            display: grid;
                            grid-template-columns: 47% 47%;
                            justify-content: space-between;
                        }

                        .form-field {
                            width: 100%;
                            display: flex;
                            gap: 20px;
                        }

                        .form-select-field {
                            grid-column: 1/ span 2;
                            display: flex;
                        }

                        .form-field-button {
                            width: max-content;
                            height: 38px;

                            .btn {
                                button {
                                    padding: 5px 15px;
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}