.widget-preview {
    .weather-container {
        position: relative;
        width: 100%;

        .back-button {
            width: 30px;
            height: 30px;
            margin: 20px;
            cursor: pointer;
            display: inline-block;
        }

        .save-button {
            position: absolute;
            top: 15px;
            right: 35px;
        }
    }
    .html-content{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // padding: 10%;
        .html-container{
            text-align: center;
            width: 100%;
        }
    }

    .container-body {
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 5%;

        .widget-body-container {
            display: flex;
            gap: 50px;

            .widget-body-group {
                display: flex;
                flex-direction: column;
                margin-top: 5px;

                .widget-degree {
                    font-weight: 500;
                    font-size: 25px;
                }
            }

            .widget-group {
                display: flex;
                flex-direction: column;

                .widget-location {
                    .location-name {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}