.integration {
    padding: 30px 25px 0 25px;

    .title {
        h1 {
            font-size: 20px;
            font-weight: 600;
        }
    }

    .integration-grid {
        border-radius: 6px;
        border: 0.25px solid $grey-2;
        width: 100%;
        height: 100vh;
        margin-top: 25px;
        background-color: $white-color;
        padding: 25px;


        .grid-item {
            width: 193px;
            height: max-content;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            cursor: pointer;

            .grid-box {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                transition: 0.35s ease;

                .btn {
                    width: max-content;
                }

                .btn .dark-button {
                    width: max-content;
                    padding: 5px 20px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: block;
                    opacity: 0;
                    transition: all .2s ease-in-out;
                }

                .btn .default-button {
                    position: absolute;
                    right: 10px;
                    bottom: 5px;
                    padding: 1px 5px 1px 5px;
                    border: none;
                    width: max-content;
                    background-color: $edit-btn-purple-color !important;
                }
                .btn .default-button span{
                    color: $white-color !important;
                    font-size: 13px;
                }
            }

            .bottom {
                display: flex;
                justify-content: space-between;

                .title {
                    font-size: 14px;
                    color: $title-color;
                }

                .toggle-button {
                    display: flex;
                    gap: 10px;
                    opacity: 0;
                    transition: all .2s ease-in-out;

                    .switch {
                        position: relative;
                        display: inline-block;
                        width: 47px;
                        height: 21px;

                        input {
                            opacity: 0;
                            width: 0;
                            height: 0;
                        }

                        .slider {
                            position: absolute;
                            cursor: pointer;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: #ccc;
                            transition: .4s;
                            border-radius: 34px;

                            &:before {
                                position: absolute;
                                content: "";
                                height: 17px;
                                width: 17px;
                                left: 2px;
                                bottom: 2px;
                                background-color: white;
                                transition: 0.4s;
                                border-radius: 50%;
                            }
                        }

                        input:checked+.slider {
                            background-color: $success-button-background-color;
                        }

                        input:checked+.slider:before {
                            transform: translateX(26px);
                        }

                        &.round {
                            .slider {
                                border-radius: 34px;

                                &:before {
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
            }

        }

        .grid-item:hover .toggle-button {
            opacity: 1;
        }

        .grid-item:hover .grid-box .dark-button {
            opacity: 1;
            /* Change the opacity to 1 */
        }
    }
}
