.available-content-layout-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;

  .available-layout-type-wrap {
    margin: 25px 0 15px;

    h1 {
      margin: 0 0 15px;
      font-size: 14px;
      font-weight: 600;
      color: $title-color;
    }

    .available-layout-type {
      display: flex;
      flex-wrap: wrap;
      gap: 45px;

      .single-layout-wrap {
        display: flex;
        flex-direction: column;
        gap: 7px;
        cursor: pointer;

        .single-layout-landscape {
          width: 214px;
          height: 131px;
        }

        .single-layout-portrait {
          width: 170px;
          height: 189px;
        }

        .single-layout-landscape,
        .single-layout-portrait {

          .single-layout-grid {
            position: relative;

            width: 100%;
            height: 100%;
            display: grid;
            border: 1px solid $title-color;
            border-radius: 6px;
            overflow: hidden;

            .each-layout {
              position: relative;
              background-color: $white-color;
              // transition: all .2s ease-in-out;

              &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-right: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
                pointer-events: none;
                // transition: all .2s ease-in-out;
              }

              &:last-child::after {
                border: none;
              }

              &:nth-child(n + 1)::after {
                border-top: none;
              }

              &:nth-child(n)::after {
                border-left: none;
              }

              // Remove right border for the last element in each row
              &.no-right-border::after {
                border-right: none;
              }
            }

            .each-layout-ar {
              position: relative;
              background-color: $white-color;
              // transition: all .2s ease-in-out;

              &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-left: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
                pointer-events: none;
                // transition: all .2s ease-in-out;
              }

              &:last-child::after {
                border: none;
              }

              &:nth-child(n + 1)::after {
                border-top: none;
              }

              &:nth-child(n)::after {
                border-right: none;
              }

              // Remove right border for the last element in each row
              &.no-left-border::after {
                border-left: none;
              }
            }

            .use-layout-wrap {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              width: max-content;
              padding: 5px 0;
              background-color: $title-color;

              display: flex;
              align-items: center;
              opacity: 0;
              // transition: all .2s ease-in;

              span {
                display: flex;
              }

              p {
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: $white-color;
              }
            }
          }
        }

        h2 {
          margin: 0;
          font-size: 13px;
          font-weight: 500;
          color: $title-color;
        }

        &:hover {

          .single-layout-landscape,
          .single-layout-portrait {

            .single-layout-grid {
              .each-layout {
                position: relative;
                background-color: $title-color;

                &::after {
                  content: '';
                  border-right: 1px solid $white-color;
                  border-bottom: 1px solid $white-color;
                }

                &:last-child::after {
                  border: none;
                }

                &:nth-child(n + 1)::after {
                  border-top: none;
                }

                &:nth-child(n)::after {
                  border-left: none;
                }

                // Remove right border for the last element in each row
                &.no-right-border::after {
                  border-right: none;
                }
              }

              .each-layout-ar {
                position: relative;
                background-color: $title-color;

                &::after {
                  content: '';
                  border-left: 1px solid $white-color;
                  border-bottom: 1px solid $white-color;
                }

                &:last-child::after {
                  border: none;
                }

                &:nth-child(n + 1)::after {
                  border-top: none;
                }

                &:nth-child(n)::after {
                  border-right: none;
                }

                // Remove right border for the last element in each row
                &.no-left-border::after {
                  border-left: none;
                }
              }

              .use-layout-wrap {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}