.license-container {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(90deg, #19A799 0%, #55BA89 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .logo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logo-image {
            height: auto;
            width: 256px;
            object-fit: contain;
        }
    }

    .loader-container {
        width: 19%;
        height: 10px;
        background-color: #f3f3f3;
        border-radius: 5px;
        overflow: hidden;
    }

    .loader-bar {
        width: 0;
        height: 100%;
        background-color: #2f2e2e;
        border-radius: 5px;
        animation: load 12s ease-in-out infinite;
    }

    @keyframes load {
        0% {
            width: 0;
        }

        100% {
            width: 100%;
        }
    }

    $background-color: white;
    $primary-color: #00C292;
    $button-color: #28a745;
    $button-hover-color: #218838;
    $shadow-color: rgba(0, 0, 0, 0.1);

    .license-activation-done {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        background-color: $background-color;
        border-radius: 10px;
        box-shadow: 0px 4px 20px $shadow-color;

        .tick-image {
            width: 100px;
            height: 100px;
            background-color: $primary-color;
            border-radius: 50%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                content: "";
                width: 0;
                height: 0;
                border-radius: 5px;
                border-right: 10px solid white;
                border-bottom: 10px solid white;
                transform: rotate(54deg);
                animation: drawCheckmark 0.5s ease forwards;
            }

            .confetti {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #ffce31;
                animation: confetti-fall 1s ease-out forwards;

                // You can customize this further with specific positions for each confetti element.
            }
        }

        .footer-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                font-size: 24px;
                color: #333;
                margin-top: 20px;
            }

            .sub-content {
                text-align: center;
                font-size: 14px;
                color: #666;
                margin-top: 10px;

                span {
                    display: block;
                    margin-bottom: 5px;
                }
            }

            button {
                background-color: $button-color;
                border: none;
                padding: 10px 20px;
                color: white;
                font-size: 16px;
                border-radius: 5px;
                cursor: pointer;
                margin-top: 20px;

                &:hover {
                    background-color: $button-hover-color;
                }
            }
        }
    }

    @keyframes drawCheckmark {
        0% {
            width: 0;
            height: 0;
        }

        50% {
            width: 25px;
            height: 0;
        }

        100% {
            width: 25px;
            height: 50px;
        }
    }

    @keyframes confetti-fall {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(150px);
            opacity: 0;
        }
    }

}

.auto-generation-container {
    display: flex;
    width: 100%;
    justify-content: center;

    .password-from-container {
        border-radius: 20px;
        background-color: #fff;
        width: 30%;
        padding: 29px 50px 30px 50px;

        .form-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 32px;

            h3 {
                font-size: 20px;
                margin: 0;
            }

            p {
                color: $title-color-2;
                margin: 0;
            }

            .auto-password-btn {
                cursor: pointer;
                width: 100%;
                padding: 13px;
                border: 1px solid #CED0D3;
                border-radius: 6px;
                text-align: center;
                box-sizing: border-box;

                .btn-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    gap: 20px;

                    p {
                        margin: 0;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                    }

                    img {
                        width: 30px;
                    }
                }
            }

            .mid-password-line {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6%;

                .line {
                    width: 11vw;
                    border-top: 0.2px solid #CED0D3;
                }

            }
        }

        .password-main-container {
            padding: 34px 0 0 0;
            display: flex;
            flex-direction: column;
            gap: 27px;

            .input-box {
                .input {
                    .password-view-icon {
                        top: 38px;
                    }

                    .input-box {
                        padding: 12px 9px;
                    }
                }
            }

            .password-submit-btn {
                .btn .success-button {
                    padding: 13px 20px;
                }
            }

            .form-footer {
                display: flex;
                justify-content: center;

                .contact-support {
                    cursor: pointer;
                    padding-left: 2px;
                    color: #30A84B;
                }
            }
        }
    }

    .password-view-container {
        border-radius: 20px;
        background-color: #fff;
        width: 30%;
        padding: 29px 50px 30px 50px;

        .password-details-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .details-header {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;

                h3 {
                    font-size: 20px;
                    margin: 0;
                }

                span {
                    color: #5C646D;
                    font-size: 14px;
                }
            }

            .password-field {
                .input {
                    .password-view-icon {
                        top: 38px;
                    }

                    .input-box {
                        padding: 12px 9px;
                    }
                }
            }

            .button-field {
                .btn .success-button {
                    padding: 13px 20px;
                }
            }

            .contact-container {
                display: flex;
                justify-content: center;

                .contact-btn {
                    cursor: pointer;
                    padding-left: 2px;
                    color: #30A84B;
                }
            }
        }
    }
}